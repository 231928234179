import { createSlice } from '@reduxjs/toolkit'

export const IQACSDMActiveSlice = createSlice({
    name: 'iqacSDMActive',
    initialState: {
        iqacSDMActive: 'SyllabusRevision',
    },
    reducers: {
        setIQACSDMActive(state, action) {
            state.iqacSDMActive = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setIQACSDMActive } = IQACSDMActiveSlice.actions

export default IQACSDMActiveSlice.reducer